import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboardHeartbeat', {
        templateUrl: 'app/pages/domain/charts/heartbeat/heartbeat.html',
        controller: HeartbeatController,
        controllerAs: 'vm',
        bindings: {
            historyData: '=',
        },
    });

    HeartbeatController.$inject = ['$filter', 'gettextCatalog', 'ng2UptimeRepoService', 'moment', '$q', 'ng2SessionService'];
    /* @ngInject */
    function HeartbeatController ($filter, gettextCatalog, ng2UptimeRepoService, moment, $q, ng2SessionService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.loading = true;
            vm.downTimeList = [];
            vm.domainStatus = false;
            vm.lastDowntime = null;
            vm.uptimePercentage = 0;
            vm.successRate = 0;
            vm.uptime = null;
            vm.doughnutColors = ['#4dc0bd', '#dcdcdc'];
            vm.doughnutLabels = [gettextCatalog.getString('Uptime'), gettextCatalog.getString('Downtime')];
            getUptimes();
        }

        async function getUptimes () {
            const domainId = ng2SessionService.getDomainId();
            const uptimes = await ng2UptimeRepoService.getAll({}, domainId);
            var uptime;

            if (!Array.isArray(uptimes)) {
                vm.loading = false;
                setupDoughnutChart();
                return;
            }

            uptime = uptimes.find(function (currentUptime) {
                return currentUptime.type === 'primary';
            });

            if (!uptime && uptimes.length > 0) {
                uptime = uptimes[0];
            }

            if (uptime) {
                vm.uptime = uptime;
                showUptimeData(uptime, domainId);
            } else {
                vm.loading = false;
                setupDoughnutChart();
            }
        }

        function showUptimeData (uptime, domainId) {
            var params = {
                start_date: moment().subtract(30, 'days')
                    .startOf('day')
                    .format(),
                end_date: moment().endOf('day')
                    .format(),
                domain_id: domainId,
            };
            var promises = [];
            promises.push(ng2UptimeRepoService.getReport(uptime.id, params, contextTokens.NO_GLOBAL));
            promises.push(ng2UptimeRepoService.getDowntimes(uptime.id, params));

            $q.all(promises)
                .then(function (values) {
                    // If no pings have ever been made, the status is set to INACTIVE instead of DOWN
                    if (values[0].data.length > 0) {
                        vm.domainStatus = values[0].last_result ? 'OK' : 'DOWN';
                    } else {
                        vm.domainStatus = 'INACTIVE';
                    }
                    vm.uptimePercentage = $filter('monNumber')(parseFloat(values[0].success_rate), '0[.00]');
                    vm.successRate = values[0].success_rate;

                    vm.downTimeList = values[1]
                        .map(function (currentUptime) {
                            currentUptime.start_at = new Date(currentUptime.start_at);
                            currentUptime.end_at = new Date(currentUptime.end_at);
                            return currentUptime;
                        })
                        .sort(function (a, b) {
                            return a.start_at > b.start_at ? -1 : a.start_at < b.start_at ? 1 : 0;
                        });
                    vm.lastDowntime = vm.downTimeList.length > 0 ? vm.downTimeList[0] : null;
                }, angular.noop)
                .finally(function () {
                    setupDoughnutChart();
                    vm.loading = false;
                });
        }

        function setupDoughnutChart () {
            vm.doughnutData = [vm.successRate, 100 - vm.successRate];
            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: setPercentage(vm.successRate),
                    totalLabel: gettextCatalog.getString('Uptime last 30 days'),
                    totalFontSize: 150,
                    subFontSize: 150,
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: false,
                },
                hover: {
                    mode: null,
                },
            };
        }

        function setPercentage (percentage) {
            // To prevent the doughnut from showing 100% when it's not actually 100%
            var newPercentage = angular.copy(percentage);
            if (newPercentage > 99) {
                newPercentage = Math.floor(newPercentage * 100) / 100;
            } else if (percentage < 1) {
                newPercentage = Math.ceil(newPercentage * 100) / 100;
            }
            return $filter('monNumber')(newPercentage / 100, '0.[00] %');
        }
    }
})();
